body {
  margin: 0;
  font-family: Poppins, sans-serif, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  position: relative;
}

/* #Content {
  background: transparent;
}

#Content:after {
  content: '';
  width: 280px;
  height: 280px;
  border-radius: 50%;
  background: #1bb9a3;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(70px);
  opacity: 0.3;
  z-index: 0;
  animation: AnimateCircleFirst 20s ease-in-out infinite;
}

#Content:before {
  content: '';
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background: #1bb9a3;
  position: absolute;
  top: 0;
  opacity: 0.3;
  left: 0;
  filter: blur(70px);
  z-index: 0;
  animation: AnimateCircleSecond 20s ease-in-out infinite;
}

h2:before {
  content: '';
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: #1bb9a3;
  opacity: 0.3;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(50px);
  z-index: 0;
  animation: AnimateCircleHead 20s ease-in-out infinite;
  animation-delay: 1s;
}

@keyframes AnimationName {
  0% {
    background-position: 43% 0%;
  }
  50% {
    background-position: 58% 100%;
  }
  100% {
    background-position: 43% 0%;
  }
}

@keyframes AnimateCircleFirst {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(250px, 800px);
  }
  50% {
    transform: translate(400px, 500px);
  }
  75% {
    transform: translate(800px, 250px);
  }
  100% {
    transform: translateX(-30px, -30px);
  }
}

@keyframes AnimateCircleSecond {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(800px, 300px);
  }
  50% {
    transform: translate(200px, 600px);
  }
  75% {
    transform: translate(300px, 800px);
  }
  100% {
    transform: translateX(0, 0);
  }
}

@keyframes AnimateCircleHead {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(400px, 100px);
  }
  50% {
    transform: translate(300px, 800px);
  }
  75% {
    transform: translate(100px, 400px);
  }
  100% {
    transform: translateX(0, 0);
  }
}

@keyframes AnimateCircleHead2 {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(700px, 300px);
  }
  50% {
    transform: translate(100px, 400px);
  }
  75% {
    transform: translate(1200px, 200px);
  }
  100% {
    transform: translateX(0, 0);
  }
}

.toggle-hide,
.toggle-show {
  color: #121315;
}

.animate-circle {
}

.animate-circle:before {
} */
